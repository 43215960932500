import { DefaultTheme } from 'styled-components'

const theme = {
  breakpoints: {
    tabs: 420,
    smallest: 960,
    small: 1280,
    medium: 1366,
    large: 1920,
    largest: 2560,
  },
  colors: {
    white: '#fff',
    red: '#c00',
    lightGreen: '#d7dee4',
    darkGreen: '#19242d',
  },
  fonts: {
    sizes: {
      normal: '1rem',
    },
  },
  spaces: {
    border: '1.875rem',
  },
}

export type Theme = typeof theme

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

export default theme as DefaultTheme
