import classNames from 'classnames'
import React, { FC, useMemo } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components/macro'

import FullScreen from './FullScreen'
import Sound from './Sound'

const ControlPanel = styled.div`
  padding: 0.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 9;

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
    top: 1.5rem;
    right: 1.5rem;
  }

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
    top: ${(s) => s.theme.spaces.border};
    right: ${(s) => s.theme.spaces.border};
  }

  &.inverted {
    > button > svg {
      fill: ${(s) => s.theme.colors.white};
      stroke: ${(s) => s.theme.colors.white};
    }
  }

  > button {
    padding: 0 0.25rem;
    position: relative;
    background: none;
    border: none;

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.medium}px`}) {
      padding: 0 0.5rem;
    }
  }
`

const Controls: FC = () => {
  const location = useLocation()
  const invertedIconColour = useMemo(() => !location.pathname.includes('play'), [location])
  return (
    <ControlPanel className={classNames({ inverted: invertedIconColour })}>
      <FullScreen />
      <Sound />
    </ControlPanel>
  )
}

export default Controls
