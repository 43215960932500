import React, { FC, useCallback, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components/macro'

import { usePlayContext } from './PlayProvider'

const HomeBackground = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
  background: ${(s) => s.theme.colors.darkGreen} url('/images/background-full.jpg') no-repeat center;
  background-size: cover;
  overflow: hidden;
  transition: opacity 1s ease-in;

  &.fade-enter {
    opacity: 0;
    z-index: 1;

    &.fade-enter-active {
      opacity: 1;
    }
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 67rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media only screen and (orientation: landscape) and (max-height: ${(s) =>
      `${s.theme.breakpoints.tabs}px`}) {
    justify-content: flex-start;
  }

  img {
    @media only screen and (orientation: landscape) and (max-height: ${(s) =>
        `${s.theme.breakpoints.tabs}px`}) {
      margin-top: 1rem;
    }

    &:first-child {
      max-width: 120px;
      margin-bottom: 1rem;

      @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
        max-width: 180px;
      }
    }

    &:last-child {
      max-width: 100px;

      @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
        max-width: 180px;
      }
    }
  }

  > div {
    max-width: 50%;
    text-align: center;

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      max-width: 64%;
      margin-top: 3rem;
    }

    button {
      padding: 5px 15px;
      background: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1rem;
      color: ${(s) => s.theme.colors.darkGreen};
      transition: opacity 0.2s ease;

      @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
        padding: 10px 30px;
        font-size: 1.5rem;
      }

      @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
        font-size: 1.75rem;
      }
    }
  }

  h1,
  h3 {
    font-size: 1rem;
    color: ${(s) => s.theme.colors.white};

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      font-size: 2rem;
    }
  }
`

const Home: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const cssRef = useRef<HTMLDivElement | null>(null)
  const { sounds } = usePlayContext()

  const start = useCallback(() => {
    const bgMusic = sounds.get('BG')
    if (bgMusic && !bgMusic.playing()) {
      bgMusic.play()
    }

    history.push('/play')
  }, [history, sounds])

  return (
    <CSSTransition in nodeRef={cssRef} key={location.key} classNames="fade" timeout={300}>
      <HomeBackground ref={cssRef}>
        <Content>
          <div>
            <img src="/images/fm-logo.png" alt="Far More" />
            <h1>Far East Hospitality offers Far More</h1>
            <h3>
              Match the correct images and stand a chance to be one of the 100 lucky winners to
              receive a $10 Starbucks gift card!
            </h3>
            <button type="button" onClick={start}>
              Start Game
            </button>
          </div>
          <img src="/images/feh-logo.png" alt="Far East Hospitality" />
        </Content>
      </HomeBackground>
    </CSSTransition>
  )
}

export default Home
