import classNames from 'classnames'
import { debounce } from 'lodash-es'
import React, { FC, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/macro'

import { Option } from '../Play'
import { MatchKey, Side, usePlayContext } from '../PlayProvider'
import HotSpot from './HotSpot'

const Figure = styled.figure`
  margin: 0;
  padding: 10px 5px;
  padding-left: 3%;
  display: flex;
  align-items: center;
  position: relative;

  > button {
    margin-right: 1rem;

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      margin-right: 3rem;
    }
  }

  > img {
    width: 100%;
    max-width: 80px;
    height: auto;

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      max-width: 160px;
    }

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.medium}px`}) {
      max-width: 200px;
    }

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
      max-width: 280px;
    }
  }

  > figcaption {
    width: 100%;
    max-width: 200px;
    margin-left: 1rem;
    font-size: 0.875rem;
    color: ${(s) => s.theme.colors.darkGreen};
    transition: opacity 0.2s ease 0.2s;
    opacity: 0;

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.small}px`}) {
      max-width: 220px;
      margin-left: 1.5rem;
    }

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.small}px`}) {
      max-width: 260px;
      margin-left: 3rem;
      font-size: 1.25rem;
    }

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
      font-size: 1.75rem;
    }
  }

  &.winner {
    > figcaption {
      opacity: 1;
    }
  }

  &:not(.selected) {
    cursor: grab;
    opacity: 1;
  }
`

interface Props extends Option {
  optionClickHandler: (answer: MatchKey, side: Side) => void
  imageLoadHandler: (width?: number) => void
}

const ImageOption: FC<Props> = ({
  answer,
  image,
  caption,
  optionClickHandler,
  imageLoadHandler,
}) => {
  const { gameState, matchedOptions, selectedLogo } = usePlayContext()
  const [imageLoaded, setImageLoaded] = useState(false)
  const imgRef = useRef<HTMLImageElement | null>(null)
  const selected = useMemo(
    () => matchedOptions.includes(answer) && gameState !== 'WIN',
    [answer, gameState, matchedOptions],
  )

  const clickHandler = useCallback(() => {
    if (!selected && selectedLogo) {
      optionClickHandler(answer, 'RIGHT')
    }
  }, [answer, optionClickHandler, selected, selectedLogo])

  const loadedHandler = useCallback(() => {
    if (imgRef.current) {
      imageLoadHandler()
      setImageLoaded(true)
    }
  }, [imageLoadHandler])

  useLayoutEffect(() => {
    const handleResize = debounce((): void => {
      loadedHandler()
    }, 200)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [loadedHandler])

  return (
    <Figure
      className={classNames({ selected, winner: gameState === 'WIN' })}
      onClick={clickHandler}
    >
      <HotSpot answer={answer} loaded={imageLoaded} side="RIGHT" />
      <img ref={imgRef} src={image} alt={caption} onLoad={loadedHandler} />
      <figcaption>{caption}</figcaption>
    </Figure>
  )
}

export default ImageOption
