import { Howl, Howler, HowlOptions } from 'howler'
import { noop } from 'lodash-es'
import React, {
  createContext,
  Dispatch,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'

export type Side = 'LEFT' | 'RIGHT'
export type MatchKey = 'ASSURED' | 'CHOICES' | 'REWARDING'
export type Sound = 'BG' | 'LEFT_CLICK' | 'RIGHT_CLICK' | 'OH_NO' | 'CONGRATS'
export type GameState = 'WIN' | 'LOSE' | 'PLAYING'

interface SoundEffect extends HowlOptions {
  key: Sound
}

const soundEffects: SoundEffect[] = [
  {
    key: 'BG',
    src: '/sounds/bg.mp3',
    html5: true,
    loop: true,
    volume: 0.3,
  },
  {
    key: 'LEFT_CLICK',
    src: '/sounds/click-left.mp3',
  },
  {
    key: 'RIGHT_CLICK',
    src: '/sounds/click-right.mp3',
  },
  {
    key: 'OH_NO',
    src: '/sounds/oh-no.mp3',
  },
  {
    key: 'CONGRATS',
    src: '/sounds/congrats.mp3',
  },
]

export interface Position {
  x: number
  y: number
}

interface HotSpotAction {
  answer: MatchKey
  side: Side
  position: Position
}

interface PlayContextValue {
  selectedLogo?: string
  setSelectedLogo: (logo: MatchKey) => void
  selectedOption?: string
  setSelectedOption: (option: MatchKey) => void
  matchedLogos: MatchKey[]
  matchedOptions: MatchKey[]
  correctMatches: MatchKey[]
  gameState: GameState
  allCorrect: boolean
  hotSpots: Record<MatchKey, Record<Side, Position>>
  updateHotSpots: Dispatch<HotSpotAction>
  resetGame: () => void
  sounds: Map<Sound, Howl>
}

export const PlayContext = createContext<PlayContextValue>({
  setSelectedLogo: noop,
  setSelectedOption: noop,
  matchedLogos: [],
  matchedOptions: [],
  correctMatches: [],
  gameState: 'PLAYING',
  allCorrect: false,
  hotSpots: {
    ASSURED: { LEFT: { x: 0, y: 0 }, RIGHT: { x: 0, y: 0 } },
    CHOICES: { LEFT: { x: 0, y: 0 }, RIGHT: { x: 0, y: 0 } },
    REWARDING: { LEFT: { x: 0, y: 0 }, RIGHT: { x: 0, y: 0 } },
  },
  updateHotSpots: noop,
  resetGame: noop,
  sounds: new Map(),
})

export const usePlayContext = (): PlayContextValue => useContext(PlayContext)

function hotSpotsReducer(
  hotSpots: Record<MatchKey, Record<string, Position>>,
  action: HotSpotAction,
) {
  const { answer, side, position } = action
  const currentAnswer = hotSpots[answer]

  return {
    ...hotSpots,
    [answer]: {
      ...currentAnswer,
      [side]: position,
    },
  }
}

const PlayProvider: FC = ({ children }) => {
  const [selectedLogo, setSelectedLogo] = useState<MatchKey | undefined>(undefined)
  const [selectedOption, setSelectedOption] = useState<MatchKey | undefined>(undefined)
  const [matchedLogos, setMatchedLogos] = useState<MatchKey[]>([])
  const [matchedOptions, setMatchedOptions] = useState<MatchKey[]>([])
  const [correctMatches, setCorrectMatches] = useState<MatchKey[]>([])
  const sounds: Map<Sound, Howl> = useMemo(() => new Map(), [])
  const [hotSpots, updateHotSpots] = useReducer(hotSpotsReducer, {
    ASSURED: { LEFT: { x: 0, y: 0 }, RIGHT: { x: 0, y: 0 } },
    CHOICES: { LEFT: { x: 0, y: 0 }, RIGHT: { x: 0, y: 0 } },
    REWARDING: { LEFT: { x: 0, y: 0 }, RIGHT: { x: 0, y: 0 } },
  })

  useEffect(() => {
    Howler.unload()

    for (const sound of soundEffects) {
      const howl = new Howl({ ...sound })
      howl.once('load', function () {
        sounds.set(sound.key, howl)
      })
    }
  }, [sounds])

  const allCorrect = useMemo(() => {
    if (selectedLogo && selectedOption) {
      if (selectedLogo === selectedOption) {
        setCorrectMatches([...correctMatches, selectedOption])
      }

      setMatchedLogos([...matchedLogos, selectedLogo])
      setMatchedOptions([...matchedOptions, selectedOption])
      setSelectedLogo(undefined)
      setSelectedOption(undefined)
    }

    return correctMatches.length === 3
  }, [correctMatches, matchedLogos, matchedOptions, selectedLogo, selectedOption])

  const gameState: GameState = useMemo(() => {
    if (allCorrect) {
      const winSound = sounds.get('CONGRATS')
      winSound?.play()
      return 'WIN'
    } else {
      if (matchedOptions.length === 3) {
        const loseSound = sounds.get('OH_NO')
        loseSound?.play()
        return 'LOSE'
      }
    }

    return 'PLAYING'
  }, [allCorrect, matchedOptions.length, sounds])

  const resetGame = useCallback(() => {
    setSelectedLogo(undefined)
    setSelectedOption(undefined)
    setMatchedLogos([])
    setMatchedOptions([])
    setCorrectMatches([])
  }, [])

  const values: PlayContextValue = {
    selectedLogo,
    setSelectedLogo,
    selectedOption,
    setSelectedOption,
    matchedLogos,
    matchedOptions,
    correctMatches,
    gameState,
    allCorrect,
    hotSpots,
    updateHotSpots,
    resetGame,
    sounds,
  }

  return <PlayContext.Provider value={values}>{children}</PlayContext.Provider>
}

export default PlayProvider
