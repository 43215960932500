import React, { FC } from 'react'

export const IconSoundOff: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinecap="round"
    strokeMiterlimit="10"
    clipRule="evenodd"
    viewBox="0 0 295 191"
  >
    <clipPath id="a">
      <path d="M0 0h294.216v190.978H0z" />
    </clipPath>
    <g clipPath="url(#a)">
      <path
        fillRule="nonzero"
        d="M119.936 2.828A154.56 154.56 0 0134.24 43.286L9.108 46.558A10.46 10.46 0 000 56.932v77.114a10.46 10.46 0 009.108 10.374l25.132 3.274a154.543 154.543 0 0185.696 40.456l.002.002a10.464 10.464 0 007.152 2.826c5.772 0 10.452-4.678 10.454-10.45.002-33.32-.006-136.796-.008-170.092C137.534 4.67 132.86 0 127.096 0h-.004a10.472 10.472 0 00-7.156 2.828"
      />
      <path
        fill="none"
        strokeWidth="18.78"
        d="M191.614 66.242l58.493 58.491M250.107 66.244l-58.493 58.49"
      />
    </g>
  </svg>
)

export const IconSoundOn: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinecap="round"
    strokeMiterlimit="10"
    clipRule="evenodd"
    viewBox="0 0 295 191"
  >
    <clipPath id="a">
      <path d="M0 0h294.216v190.978H0z" />
    </clipPath>
    <g clipPath="url(#a)">
      <path
        fillRule="nonzero"
        d="M119.936 2.828A154.56 154.56 0 0134.24 43.286L9.108 46.558A10.46 10.46 0 000 56.932v77.114a10.46 10.46 0 009.108 10.374l25.132 3.274a154.543 154.543 0 0185.696 40.456l.002.002a10.464 10.464 0 007.152 2.826c5.772 0 10.452-4.678 10.454-10.45.002-33.32-.006-136.796-.008-170.092C137.534 4.67 132.86 0 127.096 0h-.004a10.472 10.472 0 00-7.156 2.828"
      />
      <path
        fill="none"
        strokeWidth="18.78"
        d="M173.017 64.575s23.656 25.802 0 61.828M213.165 40.821s41.832 45.628 0 109.336M257.394 19.462s58.176 63.456 0 152.052"
      />
    </g>
  </svg>
)

export const IconFullScreen: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.2 93.15">
    <path d="M69.19 2.75l19.27.11-41.86 41.8L4.7 2.71h19.37a1.26 1.26 0 001-.39 1.24 1.24 0 00.38-1A1.24 1.24 0 0025 .4a1.25 1.25 0 00-1-.39H1.41a1.25 1.25 0 00-1 .39 1.23 1.23 0 00-.38 1L0 24a1.25 1.25 0 00.39 1 1.25 1.25 0 001 .39 1.24 1.24 0 001-.39 1.21 1.21 0 00.39-1V4.65l41.9 42L2.71 88.5V69.13a1.24 1.24 0 00-.39-1 1.21 1.21 0 00-1-.39 1.24 1.24 0 00-1 .38 1.26 1.26 0 00-.39 1v22.67a1.25 1.25 0 00.39 1 1.24 1.24 0 001 .39l22.57-.09a1.23 1.23 0 001-.38 1.25 1.25 0 00.39-1 1.21 1.21 0 00-.39-1 1.24 1.24 0 00-1-.39H4.55l42-41.9 41.76 41.87-19.31.09a1.36 1.36 0 100 2.71h22.69a1.32 1.32 0 001.36-1.35L93 69.19a1.36 1.36 0 10-2.71 0l-.11 19.27L48.44 46.7 90.29 4.9l.09 19.26a1.36 1.36 0 102.71 0l.11-22.55a1.25 1.25 0 00-.39-1 1.24 1.24 0 00-1-.39L69.19 0a1.25 1.25 0 00-1 .39 1.25 1.25 0 00-.39 1 1.24 1.24 0 00.39 1 1.21 1.21 0 001 .36z" />
  </svg>
)
