import axios from 'axios'
import classNames from 'classnames'
import { Formik } from 'formik'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components/macro'
import * as Yup from 'yup'

import Modal from './Modal'
import { usePlayContext } from './PlayProvider'

const FormBackground = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
  background: ${(s) => s.theme.colors.darkGreen} url('/images/background-full.jpg') no-repeat center;
  background-size: cover;
  overflow: hidden;
  transition: opacity 1s ease-in;

  &.fade-enter {
    opacity: 0;
    z-index: 1;

    &.fade-enter-active {
      opacity: 1;
    }
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 82rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media only screen and (orientation: landscape) and (max-height: ${(s) =>
      `${s.theme.breakpoints.tabs}px`}) {
    justify-content: flex-start;
  }

  h3,
  p,
  a {
    color: ${(s) => s.theme.colors.white};
  }

  > div {
    &:first-child {
      margin-top: 1rem;
      text-align: center;

      @media only screen and (orientation: landscape) and (max-height: ${(s) =>
          `${s.theme.breakpoints.tabs}px`}) {
        margin-bottom: 0.5rem;
      }

      @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
        margin-top: 8rem;
      }
    }

    h3 {
      font-size: 1.5rem;

      @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
        font-size: 2rem;
      }

      @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
        font-size: 3.25rem;
      }
    }

    p {
      max-width: 70%;
      margin: 0 auto;
      font-size: 1rem;

      @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
        font-size: 1.6rem;
      }

      @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
        font-size: 2rem;
      }
    }
  }

  > footer {
    max-width: 45rem;
    text-align: center;

    p {
      font-size: 0.625rem;
      line-height: 1.5;

      @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
        font-size: 0.875rem;
      }

      @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
        font: 1.125rem;
      }
    }
  }

  .modal-content {
    width: 560px;
  }
`

const FormWrapper = styled.div`
  width: 47rem;
  max-width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
    max-width: 100%;
  }

  input {
    width: 100%;
    padding: 5px 0;
    font-size: 1rem;
    color: ${(s) => s.theme.colors.darkGreen};
    text-align: center;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: all 0.2s ease;

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      padding: 1rem 0;
      font-size: 1.5rem;
    }

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
      font-size: 2rem;
    }

    &.error {
      border-color: ${(s) => s.theme.colors.red};

      + div {
        display: block;
      }
    }
  }
`

const Error = styled.p`
  margin-top: 0.5rem;
  display: none;
  font-family: 'Metropolis-Medium', Arial, Helvetica, sans-serif;
  color: ${(s) => s.theme.colors.white};
  font-size: 1rem;
  text-align: center;

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
    font-size: 1.125rem;
  }
`

const NameInput = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
    margin-bottom: 20px;
  }

  > label {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50%;

    &:first-child {
      padding-right: 20px;
    }
  }
`

const Submit = styled.div`
  margin-top: 10px;
  text-align: center;

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
    margin-top: 20px;
  }

  > button {
    padding: 5px 15px;
    background: ${(s) => s.theme.colors.white};
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    color: ${(s) => s.theme.colors.darkGreen};
    transition: opacity 0.2s ease;

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      padding: 10px 30px;
      font-size: 1.5rem;
    }

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
      font-size: 1.75rem;
    }

    &:disabled {
      opacity: 0.6;
    }
  }

  ${Error} {
    display: block;
  }
`

const ThanksContent = styled.div`
  text-align: center;

  p {
    margin: 0;
    font-size: 1rem;
    color: ${(s) => s.theme.colors.darkGreen};

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      font-size: 1.5rem;
    }

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
      font-size: 2rem;
    }
  }
`

type ApiStatuses = 'ok' | 'not_ok' | 'email_exists'
interface ApiResponse {
  status: ApiStatuses
}

const Form: FC = () => {
  const history = useHistory()
  const { key } = useLocation()
  const { gameState, resetGame } = usePlayContext()
  const cssRef = useRef<HTMLDivElement | null>(null)
  const [submitted, setSubmitted] = useState(false)
  const [submissionError, setSubmissionError] = useState<ApiStatuses | undefined>()

  useEffect(() => {
    if (gameState !== 'WIN') {
      history.replace('/')
    }
  }, [gameState, history])

  const backToHome = useCallback(() => {
    resetGame()
    history.replace('/')
  }, [history, resetGame])

  return (
    <CSSTransition in nodeRef={cssRef} key={key} classNames="fade" timeout={300}>
      <FormBackground ref={cssRef}>
        <Content>
          <div>
            <h3>Congratulations, you did it!</h3>
            <p>
              Enter your name and email address to be one of 100 lucky winners to receive a $10
              Starbucks gift card!
            </p>
          </div>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitted(false)
              setSubmissionError(undefined)

              const body = new FormData()
              body.append('firstName', values.firstName)
              body.append('lastName', values.lastName)
              body.append('email', values.email)

              axios
                .post<ApiResponse>('/api.php', body)
                .then(function (response) {
                  if (response.data.status === 'ok') {
                    setSubmitted(true)
                  } else if (response.data.status === 'email_exists') {
                    setSubmissionError('email_exists')
                  }
                })
                .catch(function (error) {
                  console.log(error)
                  setSubmissionError('not_ok')
                })

              setSubmitting(false)
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required(),
              lastName: Yup.string().required(),
              email: Yup.string().email().required(),
            })}
            validateOnMount
          >
            {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, isValid }) => (
              <form action="#" onSubmit={handleSubmit}>
                <FormWrapper>
                  <NameInput>
                    <label htmlFor="firstName">
                      <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={values.firstName}
                        onChange={handleChange}
                        className={classNames({ error: errors.firstName && touched.firstName })}
                      />
                      <Error>Please enter your first name</Error>
                    </label>
                    <label htmlFor="lastName">
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={values.lastName}
                        onChange={handleChange}
                        className={classNames({ error: errors.lastName && touched.lastName })}
                      />
                      <Error>Please enter your last name</Error>
                    </label>
                  </NameInput>
                  <label htmlFor="email">
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email address here"
                      value={values.email}
                      onChange={handleChange}
                      className={classNames({ error: errors.email && touched.email })}
                    />
                    <Error>Please enter your email address</Error>
                  </label>
                  <Submit>
                    <button type="submit" disabled={isSubmitting || !isValid}>
                      Submit
                    </button>
                    {submissionError === 'email_exists' && (
                      <Error>This email address has already been entered.</Error>
                    )}
                    {submissionError === 'not_ok' && (
                      <Error>
                        There was an error with your submission. Please try again later.
                      </Error>
                    )}
                  </Submit>
                </FormWrapper>
              </form>
            )}
          </Formik>
          <footer>
            <p>
              Terms &amp; Conditions Apply. &bull; Only applicable for participants residing in
              Singapore. &bull; <br />
              Winners will be notified by email.
              <br />
              <a
                href="https://www.fareasthospitality.com/en/Privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.fareasthospitality.com/en/Privacy
              </a>
            </p>
          </footer>

          <Modal visible={submitted} clickHandler={backToHome}>
            <ThanksContent>
              <Link to="/">
                <p>Thank you for your participation!</p>
              </Link>
            </ThanksContent>
          </Modal>
        </Content>
      </FormBackground>
    </CSSTransition>
  )
}

export default Form
