import { createGlobalStyle, css } from 'styled-components'

import MetropolisLight from './fonts/Metropolis-Light.woff'
import MetropolisMedium from './fonts/Metropolis-Medium.woff'

export const globalCss = css`
  @font-face {
    font-family: 'Metropolis-Light';
    src: url(${MetropolisLight}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Metropolis-Medium';
    src: url(${MetropolisMedium}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
    touch-action: manipulation;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  html {
    height: 100%;
  }

  body {
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${(s) => s.theme.colors.lightGreen};
    font-family: 'Metropolis-Light', Arial, Helvetica, sans-serif;
    font-size: 16px;
  }

  #root {
    min-height: 100vh;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    line-height: 1.2;
  }

  p {
    line-height: 1.2;
  }

  .content-root {
    height: 100vh;
  }
`

export const GlobalStyle = createGlobalStyle`
  ${globalCss}
`
