import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'

import AppContent from './AppContent'
import Modal from './Modal'
import PlayProvider from './PlayProvider'
import { GlobalStyle } from './globalStyle'
import theme from './theme'

const RotationWrapper = styled.div`
  display: none;

  @media (orientation: portrait) {
    display: block;
  }
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    margin: 0;
    font-size: 1rem;

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      font-size: 2.5rem;
    }
  }

  img {
    max-width: 80px;
    margin: 1.5rem 0 0;

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      max-width: none;
    }
  }
`

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <PlayProvider>
        <Router>
          <Route path="*">
            <AppContent />
          </Route>
        </Router>

        <RotationWrapper>
          <Modal visible>
            <ModalContent>
              <p>Rotate your handheld device to play!</p>
              <img src="/images/rotate-device.png" alt="Rotate your handheld device to play!" />
            </ModalContent>
          </Modal>
        </RotationWrapper>
      </PlayProvider>
    </ThemeProvider>
  )
}

export default App
