import classNames from 'classnames'
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import { debounce } from 'lodash-es'
import React, { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components/macro'

import Modal, { Overlay } from './Modal'
import { MatchKey, Side, usePlayContext } from './PlayProvider'
import Connectors from './play/Connectors'
import ImageOption from './play/ImageOption'
import LogoOption from './play/LogoOption'

const Stage = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${(s) => s.theme.colors.lightGreen};
  border: ${(s) => `1rem solid ${s.theme.colors.darkGreen}`};
  overflow: hidden;
  transition: opacity 1s ease-in;

  @media only screen and (orientation: landscape) and (min-height: ${(s) =>
      `${s.theme.breakpoints.tabs}px`}) {
    min-height: 100vh;
  }

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
    border-width: 1.5rem;
  }

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
    border-width: ${(s) => s.theme.spaces.border};
  }

  &.fade-enter {
    opacity: 0;
    z-index: 1;

    &.fade-enter-active {
      opacity: 1;
    }
  }
`

const InnerStage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.2s ease-out;
  transform: translate3d(0px, 0, 0);

  @media only screen and (orientation: landscape) and (max-height: ${(s) =>
      `${s.theme.breakpoints.tabs}px`}) {
    margin-top: 1rem;
    align-self: flex-start;
  }

  &.winner {
    transform: translate3d(-15%, 0, 0);

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      transform: translate3d(-12%, 0, 0);
    }

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.medium}px`}) {
      transform: translate3d(-13%, 0, 0);
    }

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
      transform: translate3d(-5%, 0, 0);
    }
  }
`

const DemoWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Demo = styled.div`
  width: 260px;
  max-width: 100%;
  text-align: center;

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
    width: 340px;
  }

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
    width: 600px;
  }

  > p {
    font-family: 'Metropolis-Medium', Arial, Helvetica, sans-serif;
    color: ${(s) => s.theme.colors.darkGreen};
    font-size: 1rem;

    > small {
      font-size: 0.875rem;
    }

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      font-size: 2rem;

      > small {
        font-size: 1.5rem;
      }
    }

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
      font-size: 3.25rem;

      > small {
        font-size: 2.5rem;
      }
    }
  }
`

const Cursor = styled.img`
  max-width: 1rem;
  height: auto;
  position: absolute;
  top: 25%;
  left: 50%;
  opacity: 0;

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
    max-width: 2rem;
  }
`

const Row = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  transform: translate3d(15%, 0, 0);

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
    height: 170px;
    transform: translate3d(12%, 0, 0);
  }

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.medium}px`}) {
    height: 210px;
    transform: translate3d(15%, 0, 0);
  }

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
    height: 300px;
    transform: translate3d(12.5%, 0, 0);
  }

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.largest}px`}) {
    transform: translate3d(16%, 0, 0);
  }

  > * {
    max-width: 50%;
    flex-basis: 50%;
    display: flex;
  }
`

const LoseContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    margin: 0;
    font-size: 1.5rem;

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      font-size: 2rem;
    }

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
      font-size: 2.5rem;
    }

    &.cta {
      cursor: pointer;
      font-family: 'Metropolis-Medium', Arial, Helvetica, sans-serif;
      font-size: 1rem;

      em {
        font-style: normal;
        text-decoration: underline;
      }

      @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
        font-size: 1.5rem;
      }

      @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
        font-size: 2rem;
      }
    }
  }

  img {
    margin: 1.5rem 0;
    max-width: 100px;

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.medium}px`}) {
      max-width: none;
    }
  }
`

const WinScreen = styled(Link)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
`

export interface Logo {
  answer: MatchKey
  image: string
}

export interface Option extends Logo {
  caption: string
}

const REDIRECT_DELAY = 7

const LOGOS: Logo[] = [
  {
    answer: 'ASSURED',
    image: '/images/lhs-assured.png',
  },
  {
    answer: 'CHOICES',
    image: '/images/lhs-choices.png',
  },
  {
    answer: 'REWARDING',
    image: '/images/lhs-rewarding.png',
  },
]

const OPTIONS: Option[] = [
  {
    answer: 'REWARDING',
    image: '/images/rhs-yellow.png',
    caption: 'Rewards galore await you.',
  },
  {
    answer: 'ASSURED',
    image: '/images/rhs-green.png',
    caption: 'Highest hygiene standards for your stay.',
  },
  {
    answer: 'CHOICES',
    image: '/images/rhs-brown.png',
    caption: 'Others try to be an accommodation of choice, we offer a choice of accommodation.',
  },
]

const Play: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const cssRef = useRef<HTMLDivElement | null>(null)
  const { gameState, hotSpots, resetGame, setSelectedLogo, setSelectedOption, sounds } =
    usePlayContext()
  const leftClickSound = sounds.get('LEFT_CLICK')
  const rightClickSound = sounds.get('RIGHT_CLICK')
  const [leftWidth, setLeftWidth] = useState(0)
  const [imageLoadCount, setImageLoadCount] = useState(0)
  const [demoVisible, setDemoVisible] = useState(true)
  const stageRef = useRef<HTMLDivElement | null>(null)
  const [offsetTop, setOffsetTop] = useState(0)
  const cursorRef = useRef<HTMLImageElement | null>(null)
  const timerRef = useRef<NodeJS.Timeout>()
  const clear = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }

  gsap.registerPlugin(CSSPlugin)

  const imageLoaded = useCallback(
    (width?: number) => {
      if (width && width > leftWidth) {
        setLeftWidth(width)
      }

      if (stageRef.current) {
        setOffsetTop(stageRef.current.offsetTop)
      }

      setImageLoadCount(imageLoadCount + 1)
    },
    [imageLoadCount, leftWidth],
  )

  useLayoutEffect(() => {
    const handleResize = debounce((): void => {
      setLeftWidth(0)
      setOffsetTop(0)
      setImageLoadCount(0)
    }, 200)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (gameState === 'WIN') {
      timerRef.current = setTimeout(() => {
        history.push('/congratulations')
      }, REDIRECT_DELAY * 1_000)
    }
    return () => clear()
  }, [gameState, history])

  const optionClickHandler = useCallback(
    (answer: MatchKey, side: Side) => {
      if (gameState !== 'PLAYING') {
        return
      } else {
        if (side === 'LEFT') {
          leftClickSound?.play()
          setSelectedLogo(answer)
        } else {
          rightClickSound?.play()
          setSelectedOption(answer)
        }
      }
    },
    [gameState, leftClickSound, rightClickSound, setSelectedLogo, setSelectedOption],
  )

  useEffect(() => {
    const left = hotSpots.ASSURED.LEFT
    const right = hotSpots.ASSURED.RIGHT
    const tl = gsap.timeline({
      defaults: { duration: 1, ease: 'expo.out' },
      paused: true,
      repeat: -1,
    })

    if (cursorRef.current && (left.x !== 0 || right.x !== 0)) {
      tl.to(cursorRef.current, {
        left: left.x + 15,
        top: left.y + 15,
        opacity: 1,
        delay: 0.25,
        onComplete: () => optionClickHandler('ASSURED', 'LEFT'),
      })
      tl.to(cursorRef.current, {
        left: right.x + 15,
        top: right.y + 15,
        delay: 0.5,
        onComplete: () => optionClickHandler('ASSURED', 'RIGHT'),
      })
      tl.to(cursorRef.current, {
        opacity: 0,
        delay: 1,
        onComplete: () => resetGame(),
      })
    }

    if (demoVisible && imageLoadCount === 6) {
      tl.play()
    } else {
      tl.pause()
    }

    return () => {
      tl.clear()
    }
  }, [demoVisible, hotSpots, imageLoadCount, optionClickHandler, resetGame])

  const closeDemo = useCallback(() => {
    const bgMusic = sounds.get('BG')
    if (bgMusic && !bgMusic.playing()) {
      bgMusic?.play()
    }
    resetGame()
    setDemoVisible(false)
  }, [resetGame, sounds])
  const closeModal = useCallback(() => resetGame(), [resetGame])

  return (
    <CSSTransition in nodeRef={cssRef} key={location.key} classNames="fade" timeout={300}>
      <Stage ref={cssRef}>
        <InnerStage ref={stageRef} className={classNames({ winner: gameState === 'WIN' })}>
          <Connectors offsetTop={offsetTop} />
          {OPTIONS.map((option, index) => (
            <Row key={option.answer}>
              <LogoOption
                {...LOGOS[index]}
                leftWidth={leftWidth}
                optionClickHandler={optionClickHandler}
                imageLoadHandler={imageLoaded}
              />
              <ImageOption
                {...option}
                optionClickHandler={optionClickHandler}
                imageLoadHandler={imageLoaded}
              />
            </Row>
          ))}
        </InnerStage>

        <Overlay className={classNames({ visible: demoVisible })}>
          <DemoWrapper onClick={closeDemo}>
            <Cursor ref={cursorRef} src="/images/cursor.png" alt="cursor" />
            <Demo>
              <p>Match the different Far More logos to the correct images by clicking the dots.</p>
              <p>
                Good luck!
                <br />
                <small>Click/tap anywhere to begin.</small>
              </p>
            </Demo>
          </DemoWrapper>
        </Overlay>

        <Modal visible={gameState === 'LOSE'} clickHandler={closeModal}>
          <LoseContent>
            <p>
              Oh no!
              <br />
              You were so close!
            </p>
            <img src="/images/face-oh-no.png" alt="You were so close!" />
            <p className="cta">
              Click <em>here</em> to try again!
            </p>
          </LoseContent>
        </Modal>

        {gameState === 'WIN' && <WinScreen to="/congratulations" />}
      </Stage>
    </CSSTransition>
  )
}

export default Play
