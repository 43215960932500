import React, { FC, useCallback } from 'react'
import styled from 'styled-components/macro'

import { IconFullScreen } from './Icons'

const FullScreenIcon = styled.button`
  > svg {
    width: 0.625rem;
    height: auto;
    fill: ${(s) => s.theme.colors.darkGreen};
    stroke: ${(s) => s.theme.colors.darkGreen};
    transition: all 0.2s ease;

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      width: 1.25rem;
    }
  }
`

const FullScreen: FC = () => {
  const toggleFullScreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }, [])

  return (
    <FullScreenIcon type="button" onClick={toggleFullScreen}>
      <IconFullScreen />
    </FullScreenIcon>
  )
}

export default FullScreen
