import { Howler } from 'howler'
import React, { FC, useCallback, useState } from 'react'
import styled from 'styled-components/macro'

import { IconSoundOff, IconSoundOn } from './Icons'

const SoundIcon = styled.button`
  > svg {
    width: 1rem;
    height: auto;
    fill: ${(s) => s.theme.colors.darkGreen};
    stroke: ${(s) => s.theme.colors.darkGreen};
    transition: all 0.2s ease;

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      width: 2rem;
    }
  }
`

const Sound: FC = () => {
  const [unmute, setUnmute] = useState(true)
  const toggleSound = useCallback(() => {
    setUnmute(!unmute)
    Howler.mute(unmute)
  }, [unmute])

  return (
    <SoundIcon type="button" onClick={toggleSound}>
      {!unmute ? <IconSoundOff /> : <IconSoundOn />}
    </SoundIcon>
  )
}

export default Sound
