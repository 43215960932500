import { debounce } from 'lodash-es'
import React, { FC, useLayoutEffect, useRef } from 'react'
import styled from 'styled-components/macro'

import { MatchKey, Side, usePlayContext } from '../PlayProvider'

const Button = styled.button`
  width: 15px;
  height: 15px;
  background-color: ${(s) => s.theme.colors.darkGreen};
  border-radius: 50%;
  border: none;
  overflow: hidden;
  font-size: 0;
  color: transparent;
  pointer-events: none;

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
    width: 30px;
    height: 30px;
  }
`

interface Props {
  answer: MatchKey
  loaded: boolean
  side: Side
}

const HotSpot: FC<Props> = ({ answer, loaded, side, children }) => {
  const { updateHotSpots } = usePlayContext()
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  useLayoutEffect(() => {
    const handleResize = debounce((): void => {
      if (buttonRef.current && loaded) {
        const { x, y } = buttonRef.current.getBoundingClientRect()
        updateHotSpots({ answer, side, position: { x, y } })
      }
    }, 200)

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [answer, loaded, side, updateHotSpots])

  return (
    <Button ref={buttonRef} type="button">
      {children}
    </Button>
  )
}

export default HotSpot
