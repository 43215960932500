import React, { FC } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'

import Controls from './Controls'
import Form from './Form'
import Home from './Home'
import Play from './Play'

const AppContent: FC = () => {
  const location = useLocation()

  return (
    <TransitionGroup className="content-root">
      <Switch location={location}>
        <Route path="/play" component={Play} />
        <Route path="/congratulations" component={Form} />
        <Route path="/" component={Home} />
      </Switch>

      <Controls />
    </TransitionGroup>
  )
}

export default AppContent
