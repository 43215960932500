import React, { FC, useMemo } from 'react'
import styled from 'styled-components/macro'

import { usePlayContext } from '../PlayProvider'

const Vector = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 6px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  stroke: ${(s) => s.theme.colors.darkGreen};
  stroke-width: 2;

  @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
    top: 0;
  }
`

const OFFSET = 15
interface LineAttrs {
  x1: number
  y1: number
  x2: number
  y2: number
}

const Connectors: FC<{ offsetTop?: number }> = ({ offsetTop }) => {
  const { hotSpots, matchedLogos, matchedOptions } = usePlayContext()

  const matchedPairs: LineAttrs[] = useMemo(() => {
    const pairs = []

    for (let index = 0; index < matchedLogos.length; index++) {
      if (matchedLogos[index] && matchedOptions[index]) {
        const left = hotSpots[matchedLogos[index]].LEFT
        const right = hotSpots[matchedOptions[index]].RIGHT

        pairs.push({
          x1: left.x - OFFSET,
          y1: left.y - OFFSET - (offsetTop ?? 0),
          x2: right.x - OFFSET,
          y2: right.y - OFFSET - (offsetTop ?? 0),
        })
      }
    }

    return pairs
  }, [hotSpots, matchedLogos, matchedOptions, offsetTop])

  return (
    <Vector>
      {matchedPairs.map((pair, key) => (
        <line key={key} {...pair} />
      ))}
    </Vector>
  )
}

export default Connectors
