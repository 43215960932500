import classNames from 'classnames'
import React, { FC } from 'react'
import styled from 'styled-components'

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  transform: scale(0);

  &.visible {
    transform: scale(1);

    > div {
      opacity: 1;
      transform: scale(1);
    }
  }
`

const Dialog = styled.div`
  width: 500px;
  max-width: 100%;
  padding: 30px;
  background-color: ${(s) => s.theme.colors.white};
  border: 15px solid ${(s) => s.theme.colors.darkGreen};
  border-radius: 15px;
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  transition: all 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
`

interface Props {
  visible: boolean
  clickHandler?: () => void
}

const Modal: FC<Props> = ({ visible, clickHandler, children }) => (
  <Overlay className={classNames({ visible })}>
    <Dialog className="modal-content" onClick={clickHandler}>
      {children}
    </Dialog>
  </Overlay>
)

export default Modal
