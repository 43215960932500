import classnames from 'classnames'
import { debounce } from 'lodash-es'
import React, { FC, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/macro'

import { Logo } from '../Play'
import { MatchKey, Side, usePlayContext } from '../PlayProvider'
import HotSpot from './HotSpot'

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`

const LogoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    margin-left: -30px;
  }

  > img {
    width: 130px;
    height: auto;

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.smallest}px`}) {
      width: 215px;
    }

    @media only screen and (min-width: ${(s) => `${s.theme.breakpoints.large}px`}) {
      width: 390px;
    }
  }

  &:not(.selected) {
    cursor: grab;
    opacity: 1;
  }
`

interface Props extends Logo {
  leftWidth: number
  optionClickHandler: (answer: MatchKey, side: Side) => void
  imageLoadHandler: (width?: number) => void
}

const LogoOption: FC<Props> = ({
  answer,
  image,
  leftWidth,
  optionClickHandler,
  imageLoadHandler,
}) => {
  const { gameState, matchedLogos } = usePlayContext()
  const [imageLoaded, setImageLoaded] = useState(false)
  const imgRef = useRef<HTMLImageElement | null>(null)
  const selected = useMemo(
    () => matchedLogos.includes(answer) && gameState !== 'WIN',
    [answer, gameState, matchedLogos],
  )

  const clickHandler = useCallback(() => {
    if (!selected) {
      optionClickHandler(answer, 'LEFT')
    }
  }, [answer, optionClickHandler, selected])

  const loadedHandler = useCallback(() => {
    if (imgRef.current) {
      const { width } = imgRef.current.getBoundingClientRect()
      imageLoadHandler(width)
      setImageLoaded(true)
    }
  }, [imageLoadHandler])

  useLayoutEffect(() => {
    const handleResize = debounce((): void => {
      loadedHandler()
    }, 200)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [loadedHandler])

  return (
    <LogoWrapper>
      <LogoContent
        className={classnames({ selected })}
        style={{ width: leftWidth > 0 ? leftWidth : `auto` }}
        onClick={clickHandler}
      >
        <img
          ref={imgRef}
          src={image}
          alt={`FAR MORE ${answer.toUpperCase()}`}
          onLoad={loadedHandler}
        />
        <HotSpot answer={answer} loaded={imageLoaded} side="LEFT" />
      </LogoContent>
    </LogoWrapper>
  )
}

export default LogoOption
